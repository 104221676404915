











import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {
        WeatherTable: () => import("@/pages/components/aemet/WeatherTable.vue"),
        WeatherChart: () => import("@/pages/components/aemet/WeatherChart.vue")
    }
})
export default class RangeWeather extends Vue {}
